import './home.scss';

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, Alert, Label, Button } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate, translate } from 'react-jhipster';
import { login } from 'app/shared/reducers/authentication';
import { RouteComponentProps } from 'react-router-dom';

export interface IHomeProp extends StateProps, DispatchProps, RouteComponentProps{};

export const Home = (props: IHomeProp) => {
  const [usersInDB, setUsersInDB] = useState(false);

  useEffect(() => {
    async function thereAreUsers() {
      const response = await fetch('api/users/thereareusers');
      setUsersInDB(await response.json());
    }
    thereAreUsers();
  }, []);

  useEffect(() => {
    props.history.push('/');
  }, [props.loginSuccess]);

  useEffect(() => {
  }, [props.loginError]);

  const handleSubmit = (event, errors, values) => {
    if (errors.length === 0) {
      props.login(values.username, values.password, values.rememberMe);
    }
  }

  return (
    <>
      {props.account && props.account.login &&
        <>
          <Row>
            <Col md="12">
            <h1 className="p-3 mb-0">
              <Translate contentKey="home.title" interpolate={{ username: props.account.login }}>
                Welcome, {props.account.login}!
              </Translate>
            </h1>
            <div className="card-content">
              <Alert color="success">
                <Translate contentKey="home.logged.message" interpolate={{ username: props.account.login }}>
                  You are logged in as user {props.account.login}.
                </Translate>
              </Alert>
              </div>
            </Col>
          </Row>
        </>
      }
      {!usersInDB &&
        <>
          <div className="card-content">
            <h2><Translate contentKey="home.nousers">Create a root user to start using the application</Translate>
              <Link to="/account/register">
                <button className="btn btn-primary float-right jh-create-entity create-asset">
                  <FontAwesomeIcon icon="plus" style={{ marginRight: 5 }} />
                  <Translate contentKey="userManagement.home.createLabel">
                    Create a new User
                  </Translate>
                </button>
              </Link>
            </h2>
          </div>
        </>
      }
      {!props.isAuthenticated && usersInDB &&
        <>
          <Row className="justify-content-center card-content">
            <Col md="8">
              {props.loginError ? (
                <Alert color="danger">
                  <Translate contentKey="login.messages.error.authentication">
                    <strong>Failed to sign in!</strong> Please check your credentials and try again.
                  </Translate>
                </Alert>
              ) : null}
            </Col>
            <Col md="8">
              <AvForm onSubmit={handleSubmit}>
                <AvField
                  name="username"
                  label={translate('global.form.email.label')}
                  placeholder={translate('global.form.email.placeholder')}
                  required
                  errorMessage="Email cannot be empty!"
                  autoFocus
                />
                <AvField
                  name="password"
                  type="password"
                  label={translate('login.form.password')}
                  placeholder={translate('login.form.password.placeholder')}
                  required
                  errorMessage="Password cannot be empty!"
                />
                <AvGroup check inline>
                  <Label className="form-check-label">
                    <AvInput type="checkbox" name="rememberMe" /> <Translate contentKey="login.form.rememberme">Remember me</Translate>
                  </Label>
                </AvGroup>
                <p></p>
                <Button color="primary" type="submit">
                  <Translate contentKey="login.form.button">Sign in</Translate>
                </Button>
                <p></p>
                <Link to="/account/reset/request">
                  <Translate contentKey="login.password.forgot">Did you forget your password?</Translate>
                </Link>
              </AvForm>
            </Col>
          </Row>
        </>}
    </>
  );
};

const mapStateToProps = storeState => ({
  loginError: storeState.authentication.loginError,
  loginSuccess: storeState.authentication.loginSuccess,
  account: storeState.authentication.account,
  isAuthenticated: storeState.authentication.isAuthenticated,
  thereAreUsers: storeState.authentication.thereAreUsers
});

const mapDispatchToProps = { login };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Home);
