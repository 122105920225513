import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntities as getProviders } from 'app/entities/provider/provider.reducer';
import { getEntities as getAssetUses } from 'app/entities/asset-use/asset-use.reducer';
import { getEntity, updateEntity, createEntity, reset } from './asset.reducer';
import { getSession } from 'app/shared/reducers/authentication';
import { Storage } from 'react-jhipster';

export interface IAssetUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const AssetUpdate = (props: IAssetUpdateProps) => {
  const [assetUses, setAssetUses] = useState([]);
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  const [providerId, setProviderId] = useState('');

  const { authUser, assetEntity, providers, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/asset');
  };

  const findAssetUsesByProvider = async () => {
    const response = await fetch(`api/asset-uses/by-provider/${providerId}`,
      { headers: new Headers({ 'Authorization': `Bearer ${Storage.session.get('jhi-authenticationToken')}`}) }
    );
    setAssetUses(await response.json());
  }

  useEffect(() => {
    if (!isNew) {
      props.getEntity(props.match.params.id);
    }
    props.getSession();
    props.getProviders();
    if (!authUser.root) {
      setProviderId(authUser.providerId);
    }
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);


  useEffect(() => {
    if (assetEntity.providerId) {
      setProviderId(assetEntity.providerId);
    }
  }, [assetEntity]);

  useEffect(() => {
    if (providerId !== '') {
      findAssetUsesByProvider();
    }
  }, [providerId]);

  useEffect(() => {

  }, [assetUses]);


  const onChangeProvider = (e) => {
    if (e.target.value !== '') {
      setProviderId(e.target.value);
    }
  }

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      if (!authUser.root && values.providerId !== '') {
        values.providerId = authUser.providerId;
      }
      const entity = {
        ...assetEntity,
        ...values,
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center card-title">
        <Col md="8">
          <h2 id="assetApp.asset.home.createOrEditLabel">
            <Translate contentKey="assetApp.asset.home.createOrEditLabel">Create or edit a Asset</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center card-content">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : assetEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <AvInput id="asset-id" type="text" className="form-control" hidden name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="identifierLabel" for="asset-identifier">
                  <Translate contentKey="assetApp.asset.identifier">Identifier</Translate>
                </Label>
                <AvField
                  id="asset-identifier"
                  type="text"
                  name="identifier"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    minLength: { value: 3, errorMessage: translate('entity.validation.minlength', { min: 3 }) },
                    maxLength: { value: 20, errorMessage: translate('entity.validation.maxlength', { max: 20 }) },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="descriptionLabel" for="asset-description">
                  <Translate contentKey="assetApp.asset.description">Description</Translate>
                </Label>
                <AvField id="asset-description" type="text" name="description" />
              </AvGroup>
              <AvGroup>
                <Label id="vendorLabel" for="asset-vendor">
                  <Translate contentKey="assetApp.asset.vendor">Vendor</Translate>
                </Label>
                <AvField id="asset-vendor" type="text" name="vendor" />
              </AvGroup>
              <AvGroup check>
                <Label id="enableLabel">
                  <AvInput id="asset-enable" type="checkbox" className="form-check-input" name="enable" />
                  <Translate contentKey="assetApp.asset.enable">Enable</Translate>
                </Label>
              </AvGroup>
              {authUser.root && <AvGroup>
                <Label for="asset-provider">
                  <Translate contentKey="assetApp.asset.provider">Provider</Translate>
                </Label>
                  <AvInput id="asset-provider" type="select" className="form-control" name="providerId"
                    value={assetEntity.providerId}
                    onChange={(e) => onChangeProvider(e)}
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                  }}>
                    {providerId === '' && <option value="" key="0" />}
                    {providers
                      ? providers.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.identifier}
                        </option>
                      ))
                      : null}
                </AvInput>
              </AvGroup>}
              {(providerId !== '' || assetEntity.assetUseId) && <AvGroup>
                <Label for="asset-assetUse">
                  <Translate contentKey="assetApp.asset.assetUse">Asset Use</Translate>
                </Label>
                <AvInput id="asset-assetUse" type="select" className="form-control"
                  name="assetUseId" value={assetEntity.assetUseId}
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') }
                }}>
                  <option value="" key="0" />
                  {assetUses
                    ? assetUses.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.identifier}
                      </option>
                    ))
                    : null}
                </AvInput>
              </AvGroup>}
              <Button tag={Link} id="cancel-save" to="/asset" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  authUser: storeState.authentication.account,
  providers: storeState.provider.entities,
  assetUses: storeState.assetUse.entities,
  assetEntity: storeState.asset.entity,
  loading: storeState.asset.loading,
  updating: storeState.asset.updating,
  updateSuccess: storeState.asset.updateSuccess,
});

const mapDispatchToProps = {
  getSession,
  getProviders,
  getAssetUses,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AssetUpdate);
