import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './provider.reducer';
import { IProvider } from 'app/shared/model/provider.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IProviderDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ProviderDetail = (props: IProviderDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { providerEntity } = props;
  return (
    <Row className="justify-content-center card-title">
      <Col md="8">
        <h2>
          <Translate contentKey="assetApp.provider.detail.title">Provider</Translate> [<b>{providerEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details card-content">
          <dt>
            <span id="identifier">
              <Translate contentKey="assetApp.provider.identifier">Identifier</Translate>
            </span>
          </dt>
          <dd>{providerEntity.identifier}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="assetApp.provider.description">Description</Translate>
            </span>
          </dt>
          <dd>{providerEntity.description}</dd>
          <dt>
            <span id="topic">
              <Translate contentKey="assetApp.provider.topic">Topic</Translate>
            </span>
          </dt>
          <dd>{providerEntity.topic}</dd>
          <dt>
            <span id="organizationWizzieName">
              <Translate contentKey="assetApp.provider.organizationWizzieName">Organization Wizzie Name</Translate>
            </span>
          </dt>
          <dd>{providerEntity.organizationWizzieName}</dd>
          <dt>
            <span id="organizationWizzieId">
              <Translate contentKey="assetApp.provider.organizationWizzieId">Organization Wizzie Id</Translate>
            </span>
          </dt>
          <dd>{providerEntity.organizationWizzieId}</dd>
          <dt>
            <span id="consumerId">
              <Translate contentKey="assetApp.provider.consumerId">Consumer Id</Translate>
            </span>
          </dt>
          <dd>{providerEntity.consumerId}</dd>
        </dl>
        <Button tag={Link} onClick={props.history.goBack} replace color="info">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/provider/${providerEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ provider }: IRootState) => ({
  providerEntity: provider.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProviderDetail);
