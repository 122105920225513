import axios from 'axios';
import {
  parseHeaderForLinks,
  loadMoreDataWhenScrolled,
  ICrudGetAction,
  ICrudGetAllAction,
  ICrudPutAction,
  ICrudDeleteAction,
} from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IProvider, defaultValue } from 'app/shared/model/provider.model';
import { OrganizationWizzin } from 'app/shared/model/organization-wizzin.model';

export const ACTION_TYPES = {
  FETCH_PROVIDER_LIST: 'provider/FETCH_PROVIDER_LIST',
  FETCH_ORGANIZATIONS_WIZZIN: 'provider/FETCH_ORGANIZATIONS_WIZZIN',
  FETCH_PROVIDER: 'provider/FETCH_PROVIDER',
  CREATE_PROVIDER: 'provider/CREATE_PROVIDER',
  UPDATE_PROVIDER: 'provider/UPDATE_PROVIDER',
  DELETE_PROVIDER: 'provider/DELETE_PROVIDER',
  RESET: 'provider/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IProvider>,
  organizationsWizzin: [] as OrganizationWizzin[],
  entity: defaultValue,
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type ProviderState = Readonly<typeof initialState>;

// Reducer

export default (state: ProviderState = initialState, action): ProviderState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PROVIDER_LIST):
    case REQUEST(ACTION_TYPES.FETCH_ORGANIZATIONS_WIZZIN):
    case REQUEST(ACTION_TYPES.FETCH_PROVIDER):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_PROVIDER):
    case REQUEST(ACTION_TYPES.UPDATE_PROVIDER):
    case REQUEST(ACTION_TYPES.DELETE_PROVIDER):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_PROVIDER_LIST):
    case FAILURE(ACTION_TYPES.FETCH_ORGANIZATIONS_WIZZIN):
    case FAILURE(ACTION_TYPES.FETCH_PROVIDER):
    case FAILURE(ACTION_TYPES.CREATE_PROVIDER):
    case FAILURE(ACTION_TYPES.UPDATE_PROVIDER):
    case FAILURE(ACTION_TYPES.DELETE_PROVIDER):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_PROVIDER_LIST): {
      const links = parseHeaderForLinks(action.payload.headers.link);

      return {
        ...state,
        loading: false,
        links,
        entities: loadMoreDataWhenScrolled(state.entities, action.payload.data, links),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    }
    case SUCCESS(ACTION_TYPES.FETCH_ORGANIZATIONS_WIZZIN): {
      return {
        ...state,
        loading: false,
        organizationsWizzin: action.payload.data,
      };
    }
    case SUCCESS(ACTION_TYPES.FETCH_PROVIDER):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_PROVIDER):
    case SUCCESS(ACTION_TYPES.UPDATE_PROVIDER):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_PROVIDER):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/providers';

// Actions

export const getEntities: ICrudGetAllAction<IProvider> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_PROVIDER_LIST,
    payload: axios.get<IProvider>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getOrganizations = async () => {
  const response = await fetch(`${apiUrl}/wizzin`, {});
  const data = response.body || [];
  return {
    type: ACTION_TYPES.FETCH_ORGANIZATIONS_WIZZIN,
    payload: data,
  };
};

export const getEntity: ICrudGetAction<IProvider> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PROVIDER,
    payload: axios.get<IProvider>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IProvider> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_PROVIDER,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const updateEntity: ICrudPutAction<IProvider> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_PROVIDER,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IProvider> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_PROVIDER,
    payload: axios.delete(requestUrl),
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
