import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntity, updateEntity, createEntity, reset } from './provider.reducer';
import { getSession } from 'app/shared/reducers/authentication';
import { Storage } from 'react-jhipster';

export interface IProviderUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ProviderUpdate = (props: IProviderUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  const [organizations, setOrganizations] = useState([]);
  const { authUser, providerEntity, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/provider');
  };

  useEffect(() => {
    if (!isNew) {
      props.getEntity(props.match.params.id);
    }

    async function getOrganizations() {
      const headers = new Headers({ 'Authorization': `Bearer ${Storage.session.get('jhi-authenticationToken')}` });
      const response = await fetch('api/providers/wizzin', { headers });
      if (response.status === 200) {
        setOrganizations(await response.json());
      } else {
        setOrganizations([]);
      }
    }
    getOrganizations();
    props.getSession();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {

      const organizationSelected = organizations.find(org => org.id.toString() === values.organizationWizzieId.toString());
      values.consumerId = organizationSelected.consumer_id;
      values.organizationWizzieName = organizationSelected.name;

      const entity = {
        ...providerEntity,
        ...values,
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center card-title">
        <Col md="8">
          <h2 id="assetApp.provider.home.createOrEditLabel">
            <Translate contentKey="assetApp.provider.home.createOrEditLabel">Create or edit a Provider</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center card-content">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
              <AvForm model={isNew ? {} : providerEntity} onSubmit={saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <AvInput id="provider-id" type="text" className="form-control" hidden name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="identifierLabel" for="provider-identifier">
                    <Translate contentKey="assetApp.provider.identifier">Identifier</Translate>
                  </Label>
                  <AvField
                    id="provider-identifier"
                    type="text"
                    name="identifier"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      minLength: { value: 3, errorMessage: translate('entity.validation.minlength', { min: 3 }) },
                      maxLength: { value: 20, errorMessage: translate('entity.validation.maxlength', { max: 20 }) },
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="descriptionLabel" for="provider-description">
                    <Translate contentKey="assetApp.provider.description">Description</Translate>
                  </Label>
                  <AvField id="provider-description" type="text" name="description" />
                </AvGroup>
                <AvGroup>
                  <Label id="topicLabel" for="provider-topic">
                    <Translate contentKey="assetApp.provider.topic">Topic</Translate>
                  </Label>
                  <AvField
                    id="provider-topic"
                    type="text"
                    name="topic"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      minLength: { value: 3, errorMessage: translate('entity.validation.minlength', { min: 3 }) },
                      maxLength: { value: 20, errorMessage: translate('entity.validation.maxlength', { max: 20 }) },
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label for="organizationWizzieId">
                    <Translate contentKey="assetApp.provider.organizationWizzie">Organization Wizzie</Translate>
                  </Label>
                  <AvField type="select" name="organizationWizzieId"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                  }}>
                    <option value="" key="0" />
                    {organizations.map(organization =>
                      <option key={organization.id} value={organization.id} >
                        {organization.name}
                      </option>)}
                  </AvField>
                </AvGroup>


                {!authUser.apiTokenWizzie && <div className="alert alert-warning" role="alert">
                  <Translate contentKey="assetApp.provider.alertApiKey">
                    You need to enter a Wizzie API Key in your user
                  </Translate>
                </div>}


              <Button tag={Link} id="cancel-save" to="/provider" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  providerEntity: storeState.provider.entity,
  authUser: storeState.authentication.account,
  organizations: storeState.provider.organizationsWizzin,
  loading: storeState.provider.loading,
  updating: storeState.provider.updating,
  updateSuccess: storeState.provider.updateSuccess,
});

const mapDispatchToProps = {
  getSession,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProviderUpdate);
