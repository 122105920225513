export interface IProvider {
  id?: string;
  identifier?: string;
  description?: string;
  topic?: string;
  organizationWizzieName?: string;
  organizationWizzieId?: number;
  consumerId?: string;
}

export const defaultValue: Readonly<IProvider> = {};
