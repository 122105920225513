import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './asset.reducer';
import { IAsset } from 'app/shared/model/asset.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IAssetDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const AssetDetail = (props: IAssetDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { assetEntity } = props;
  return (
    <Row className="justify-content-center card-title">
      <Col md="8">
        <h2>
          <Translate contentKey="assetApp.asset.detail.title">Asset</Translate> [<b>{assetEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details card-content">
          <dt>
            <span id="identifier">
              <Translate contentKey="assetApp.asset.identifier">Identifier</Translate>
            </span>
          </dt>
          <dd>{assetEntity.identifier}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="assetApp.asset.description">Description</Translate>
            </span>
          </dt>
          <dd>{assetEntity.description}</dd>
          <dt>
            <span id="vendor">
              <Translate contentKey="assetApp.asset.vendor">Vendor</Translate>
            </span>
          </dt>
          <dd>{assetEntity.vendor}</dd>
          <dt>
            <span id="enable">
              <Translate contentKey="assetApp.asset.enable">Enable</Translate>
            </span>
          </dt>
          <dd>{assetEntity.enable ? 'true' : 'false'}</dd>
          <dt>
            <Translate contentKey="assetApp.asset.provider">Provider</Translate>
          </dt>
          <dd>{assetEntity.providerId ? assetEntity.providerId : ''}</dd>
          <dt>
            <Translate contentKey="assetApp.asset.assetUse">Asset Use</Translate>
          </dt>
          <dd>{assetEntity.assetUseId ? assetEntity.assetUseId : ''}</dd>
        </dl>
        <Button tag={Link} to="/asset" replace color="info">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/asset/${assetEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ asset }: IRootState) => ({
  assetEntity: asset.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AssetDetail);
