import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntities as getProviders } from 'app/entities/provider/provider.reducer';
import { getEntity, updateEntity, createEntity, reset } from './asset-use.reducer';
import { getSession } from 'app/shared/reducers/authentication';

export interface IAssetUseUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const AssetUseUpdate = (props: IAssetUseUpdateProps) => {
  const [providerId, setProviderId] = useState('0');
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { authUser, assetUseEntity, providers, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/asset-use');
  };

  useEffect(() => {
    if (!isNew) {
      props.getEntity(props.match.params.id);
    }
    props.getSession();
    props.getProviders();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      if (!authUser.root) {
        values.providerId = authUser.providerId;
      }
      const entity = {
        ...assetUseEntity,
        ...values,
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center card-title">
        <Col md="8">
          <h2 id="assetApp.assetUse.home.createOrEditLabel">
            <Translate contentKey="assetApp.assetUse.home.createOrEditLabel">Create or edit a AssetUse</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center card-content">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : assetUseEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <AvInput id="asset-use-id" hidden type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="identifierLabel" for="asset-use-identifier">
                  <Translate contentKey="assetApp.assetUse.identifier">Identifier</Translate>
                </Label>
                <AvField
                  id="asset-use-identifier"
                  type="text"
                  name="identifier"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    minLength: { value: 3, errorMessage: translate('entity.validation.minlength', { min: 3 }) },
                    maxLength: { value: 20, errorMessage: translate('entity.validation.maxlength', { max: 20 }) },
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="descriptionLabel" for="asset-use-description">
                  <Translate contentKey="assetApp.assetUse.description">Description</Translate>
                </Label>
                <AvField id="asset-use-description" type="text" name="description" />
              </AvGroup>
              {authUser.root && <AvGroup>
                <Label for="asset-use-provider">
                  <Translate contentKey="assetApp.assetUse.provider">Provider</Translate>
                </Label>
                <AvInput id="asset-use-provider" type="select" className="form-control" name="providerId"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') }
                }}>
                  <option value="" key="0" />
                  {providers
                    ? providers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.identifier}
                      </option>
                    ))
                    : null}
                </AvInput>
              </AvGroup>}
              <Button tag={Link} id="cancel-save" to="/asset-use" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  authUser: storeState.authentication.account,
  providers: storeState.provider.entities,
  assetUseEntity: storeState.assetUse.entity,
  loading: storeState.assetUse.loading,
  updating: storeState.assetUse.updating,
  updateSuccess: storeState.assetUse.updateSuccess,
});

const mapDispatchToProps = {
  getProviders,
  getEntity,
  getSession,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AssetUseUpdate);
