export interface IAsset {
  id?: string;
  identifier?: string;
  description?: string;
  vendor?: string;
  enable?: boolean;
  providerId?: string;
  assetUseId?: string;
  providerIdentifier?: string;
  assetUseIdentifier?: string;
}

export const defaultValue: Readonly<IAsset> = {
  enable: false,
};
