import axios from 'axios';
import {
  parseHeaderForLinks,
  loadMoreDataWhenScrolled,
  ICrudGetAction,
  ICrudGetAllAction,
  ICrudPutAction,
  ICrudDeleteAction,
} from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IAssetUse, defaultValue } from 'app/shared/model/asset-use.model';

export const ACTION_TYPES = {
  FETCH_ASSETUSE_LIST: 'assetUse/FETCH_ASSETUSE_LIST',
  FETCH_ASSETUSE: 'assetUse/FETCH_ASSETUSE',
  CREATE_ASSETUSE: 'assetUse/CREATE_ASSETUSE',
  UPDATE_ASSETUSE: 'assetUse/UPDATE_ASSETUSE',
  DELETE_ASSETUSE: 'assetUse/DELETE_ASSETUSE',
  RESET: 'assetUse/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IAssetUse>,
  entity: defaultValue,
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export type AssetUseState = Readonly<typeof initialState>;

// Reducer

export default (state: AssetUseState = initialState, action): AssetUseState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ASSETUSE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_ASSETUSE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_ASSETUSE):
    case REQUEST(ACTION_TYPES.UPDATE_ASSETUSE):
    case REQUEST(ACTION_TYPES.DELETE_ASSETUSE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_ASSETUSE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_ASSETUSE):
    case FAILURE(ACTION_TYPES.CREATE_ASSETUSE):
    case FAILURE(ACTION_TYPES.UPDATE_ASSETUSE):
    case FAILURE(ACTION_TYPES.DELETE_ASSETUSE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_ASSETUSE_LIST): {
      const links = parseHeaderForLinks(action.payload.headers.link);

      return {
        ...state,
        loading: false,
        links,
        entities: loadMoreDataWhenScrolled(state.entities, action.payload.data, links),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    }
    case SUCCESS(ACTION_TYPES.FETCH_ASSETUSE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_ASSETUSE):
    case SUCCESS(ACTION_TYPES.UPDATE_ASSETUSE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_ASSETUSE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/asset-uses';

// Actions

export const getEntities: ICrudGetAllAction<IAssetUse> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_ASSETUSE_LIST,
    payload: axios.get<IAssetUse>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`),
  };
};

export const getEntity: ICrudGetAction<IAssetUse> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_ASSETUSE,
    payload: axios.get<IAssetUse>(requestUrl),
  };
};

export const createEntity: ICrudPutAction<IAssetUse> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_ASSETUSE,
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const updateEntity: ICrudPutAction<IAssetUse> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_ASSETUSE,
    payload: axios.put(apiUrl, cleanEntity(entity)),
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IAssetUse> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_ASSETUSE,
    payload: axios.delete(requestUrl),
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
