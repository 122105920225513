import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, getSortState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities, reset } from './asset.reducer';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { getSession } from 'app/shared/reducers/authentication';
import { Storage } from 'react-jhipster';
import { toast } from 'react-toastify';

export interface IAssetProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const Asset = (props: IAssetProps) => {
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );
  const [sorting, setSorting] = useState(false);
  const [sendToKafkaSuccess, setSendToKafkaSuccess] = useState(false);

  const getAllEntities = () => {
    props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`);
  };

  const resetAll = () => {
    props.reset();
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    props.getEntities();
  };

  useEffect(() => {
    resetAll();
    props.getSession();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      resetAll();
    }
  }, [props.updateSuccess]);

  useEffect(() => {
    getAllEntities();
  }, [paginationState.activePage]);

  const handleLoadMore = () => {
    if ((window as any).pageYOffset > 0) {
      setPaginationState({
        ...paginationState,
        activePage: paginationState.activePage + 1,
      });
    }
  };

  useEffect(() => {
    if (sorting) {
      getAllEntities();
      setSorting(false);
    }
  }, [sorting]);

  useEffect(() => {
    if (sendToKafkaSuccess) {
      toast.success(<Translate contentKey='assetApp.asset.sendToKafkaAllMsg'></Translate>);
    }
  }, [sendToKafkaSuccess]);

  const sort = p => () => {
    props.reset();
    setPaginationState({
      ...paginationState,
      activePage: 1,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p,
    });
    setSorting(true);
  };

  const sendToKafkaAll = async () => {
    setSendToKafkaSuccess(true);
    await fetch(`api/assets/send-to-kafka-all`, {
      method: 'PUT', body: JSON.stringify(props.assetList),
      headers: new Headers({ 'Authorization': `Bearer ${Storage.session.get('jhi-authenticationToken')}` })
    });
  }

  const { authUser, assetList, match, loading } = props;
  return (
    <div>
      <h2 id="asset-heading" className="card-heading">
        <Translate contentKey="assetApp.asset.home.title">Assets</Translate>
        <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus" />
        </Link>
      </h2>
      <div className="table-responsive">
        <div style={{ padding: 12 }}>
          <button className="btn btn-primary" onClick={sendToKafkaAll} style={{ marginRight: 5 }}>
            <FontAwesomeIcon icon="sync" style={{ marginRight: 5 }} />
            <Translate contentKey="assetApp.asset.home.sendToKafkaAll">Send to Kafka</Translate>
          </button>
        </div>
        <InfiniteScroll
          pageStart={paginationState.activePage}
          loadMore={handleLoadMore}
          hasMore={paginationState.activePage - 1 < props.links.next}
          loader={<div className="loader">Loading ...</div>}
          threshold={0}
          initialLoad={false}
        >
          {assetList && assetList.length > 0 ? (
            <Table responsive striped>
              <thead>
                <tr>
                  <th className="hand" onClick={sort('identifier')}>
                    <Translate contentKey="assetApp.asset.identifier">Identifier</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className={authUser.root ? "hand d-none d-lg-table-cell" : "hand" } onClick={sort('description')}>
                    <Translate contentKey="assetApp.asset.description">Description</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className={authUser.root ? "hand d-none d-lg-table-cell" : "hand" } onClick={sort('vendor')}>
                    <Translate contentKey="assetApp.asset.vendor">Vendor</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className={authUser.root ? "hand d-none d-lg-table-cell" : "hand" } onClick={sort('enable')}>
                    <Translate contentKey="assetApp.asset.enable">Enable</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  {authUser.root &&
                    <>
                      <th className="hand" onClick={sort('providerId')}>
                        <Translate contentKey="assetApp.asset.provider">Provider</Translate> <FontAwesomeIcon icon="sort" />
                      </th>
                      <th className="hand" onClick={sort('assetUseId')}>
                        <Translate contentKey="assetApp.asset.assetUse">Asset Use</Translate> <FontAwesomeIcon icon="sort" />
                      </th>
                    </>}
                </tr>
              </thead>
              <tbody>
                {assetList.map((asset, i) => (
                  <tr key={`entity-${i}`}>
                    <td>{asset.identifier}</td>
                    <td className={authUser.root ? "hand d-none d-lg-table-cell" : "hand" }>{asset.description}</td>
                    <td className={authUser.root ? "hand d-none d-lg-table-cell" : "hand" }>{asset.vendor}</td>
                    <td className={authUser.root ? "hand d-none d-lg-table-cell" : "hand" }>{asset.enable ? 'true' : 'false'}</td>
                    {authUser.root &&
                      <>
                        <td>{asset.providerId ? <Link className="btn-link" to={`provider/${asset.providerId}`}>{asset.providerIdentifier}</Link> : ''}</td>
                        <td>{asset.assetUseId ? <Link className="btn-link" to={`asset-use/${asset.assetUseId}`}>{asset.assetUseIdentifier}</Link> : ''}</td>
                      </>}
                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        <Button tag={Link} to={`${match.url}/${asset.id}/edit`} color="primary" size="sm">
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} to={`${match.url}/${asset.id}/delete`} color="danger" size="sm">
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            !loading && (
              <div className="alert alert-warning">
                <Translate contentKey="assetApp.asset.home.notFound">No Assets found</Translate>
              </div>
            )
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

const mapStateToProps = ({ asset, authentication }: IRootState) => ({
  authUser: authentication.account,
  assetList: asset.entities,
  loading: asset.loading,
  totalItems: asset.totalItems,
  links: asset.links,
  entity: asset.entity,
  updateSuccess: asset.updateSuccess,
});

const mapDispatchToProps = {
  getEntities,
  getSession,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Asset);
